import React from 'react';

import ArticleCallToActionButton from './Article.CallToActionButton';
import ArticleImageButton from './Article.ImageButton';
import ArticleBody from './Article.Body';
import ArticleBodyAlignCenter from './Article.BodyAlignCenter';
import ArticleAnchorWithColorAlignCenter from './Article.AnchorWithColorAlignCenter';
import ArticleImageAlignCenter from './Article.ImageAlignCenter';

import { IArticleSection } from "@narative/gatsby-theme-novela/src/types";

interface ArticleSectionProps {
    section: IArticleSection
}

const ArticleSections: React.FC<ArticleSectionProps> = ({ section }) => {
    const getSectionComponent = (section) => {
        switch(section.__typename) {
            case 'ContentfulCallToActionButton':
                return <ArticleCallToActionButton section={section} />;
            case 'ContentfulImageButton' :
                return <ArticleImageButton section={section} />;
            case 'ContentfulBody' :
                return <ArticleBody section={section} />;
            case 'ContentfulAnchorWithColorAlignCenter':
                return <ArticleAnchorWithColorAlignCenter section={section} />;
            case 'ContentfulBodyAlignCenter':
                return <ArticleBodyAlignCenter section={section} />;
            case 'ContentfulImageAlignCenter':
                return <ArticleImageAlignCenter section={section} />;
            default:
                return null;
        }
    }

    return (
        <>
            {   
                section.map((section, index) => {
                    return getSectionComponent(section);
                })
            }
        </>
    )
};

export default ArticleSections;