import React from "react";
import styled from "@emotion/styled";

import Figcaption from "@narative/gatsby-theme-novela/src/components/Figcaption";
import Image, { ImagePlaceholder } from "@narative/gatsby-theme-novela/src/components/Image";

import { IArticleImageAlignCenter } from "@narative/gatsby-theme-novela/src/types";

import mediaqueries from '@styles/media';

interface ArticleImageAlignCenterProps {
    section: IArticleImageAlignCenter;
}

const ArticleImageAlignCenter = ({ section }: ArticleImageAlignCenterProps) => {
    const hasButtonImage = 
        section.image &&
        Object.keys(section.image).length !== 0 &&
        section.image.full.constructor === Object;

    return (
            <figure>
                <ImageContainer>
                    {hasButtonImage ? (
                        <ImageAlignCenter src={section.image.full} />
                    ) : (
                        <ImagePlaceholder />
                    )}
                </ImageContainer>
                <br/>
                <Figcaption>{section.figcaption}</Figcaption>
            </figure>
    );
};

export default ArticleImageAlignCenter;

const ImageAlignCenter = styled(Image) `
    display: block;
    margin: 0 auto;
    padding: 0;
    width: 100%;
    max-width: 680px;
    ${mediaqueries.desktop`
    max-width: 507px;
    `}

    ${mediaqueries.tablet`
    max-width: 486px;
    `};

    ${mediaqueries.phablet`
    padding: 0 20px;
    `};
`;

const ImageContainer = styled.div`
    display: block;
    margin: 0 auto;
    margin-top: 35px;
    padding: 0;
    width: 100%;
    max-width: 680px;
    box-shadow: 0 30px 60px -10px rgba(0, 0, 0, 0.2),
    0 18px 36px -18px rgba(0, 0, 0, 0.22);

    ${mediaqueries.desktop`
    max-width: 507px;
    `}

    ${mediaqueries.tablet`
    max-width: 486px;
    `};

    ${mediaqueries.phablet`
    padding: 0 20px;
    `};
`;
