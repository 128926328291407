import React, { useEffect, useRef, useState } from "react";
import { useStaticQuery, graphql, Link } from "gatsby"
import styled from "@emotion/styled";
import mediaqueries from "@narative/gatsby-theme-novela/src/styles/media";
import throttle from "lodash/throttle";

export interface IToC {
  title: string
}

const TableOfContents: React.FC<IToC> = (title) => {

  const toCRef = useRef<HTMLDivElement>(null);
  const [show, setShow] = useState<boolean>(false);

  useEffect(() => {
    const handleScroll = throttle(() => {
      const el = toCRef.current;

      if (!el)
        return;

      const elBox = el.getBoundingClientRect()
      const top = elBox ? elBox.top : 0;

      setShow(top + window.scrollY > imageOffsetFromTopOfWindow);
    });

    const imageRect = document
      .getElementById("ArticleImage__Hero")
      .getBoundingClientRect();

    const imageOffsetFromTopOfWindow = imageRect.top + window.scrollY;
    setShow(false);

    //based on https://www.bram.us/2020/01/10/smooth-scrolling-sticky-scrollspy-navigation/
    const observer = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        const id = entry.target.getAttribute('id');
        if (entry.intersectionRatio > 0) {
          document.querySelector(`nav ol li p[id="#${id}"]`)?.classList.add('active');
        } else {
          document.querySelector(`nav ol li p[id="#${id}"]`)?.classList.remove('active');
        }
      });
    });

    document.querySelectorAll('h2[id], h3[id], h4[id]').forEach((section) => {
      observer.observe(section);
    });


    window.addEventListener("scroll", handleScroll);
    window.addEventListener("resize", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
      window.removeEventListener("resize", handleScroll);
    };
  }, 20);

  const data = useStaticQuery(graphql`
  query Toc {
    allContentfulArticle {
      edges {
        node {
          title
          body {
            childMdx {
              tableOfContents
            }
          }
          section {
            __typename
            ... on Node {
              ... on ContentfulBody {
                id
                body {
                  childMdx {
                    tableOfContents
                    headings {
                      depth
                    }
                  }
                }
              }
              ... on ContentfulBodyAlignCenter {
                id
                body {
                  childMdx {
                    tableOfContents
                    headings {
                      depth
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  `)
  const queryData = data.allContentfulArticle.edges.find(a => a.node.title === title.article);

  // Generate Table of Contents Based on Body
  let tocData = {}[0];

  if (queryData.node.body.childMdx?.tableOfContents?.items?.length >= 1) {
    tocData = queryData.node.body.childMdx.tableOfContents.items;
  } else {
    return null;
  }

  // Generate Table of Contents Based on Section
  let sectionData = [];

  // create root of toc
  sectionData.push({
    'id': -1,
    'parentId': null,
    'title': 'root',
    'url': 'https://google.com',
    'depth': 2
  })

  if (queryData.node.section?.length >= 1) {
    queryData.node.section.forEach((section, index) => {
      if (((section.__typename === "ContentfulBody") || (section.__typename === "ContentfulBodyAlignCenter"))
        && (section.body.childMdx.headings.length >= 1)) {
        sectionData.push({
          'id': index,
          'parentId': 0,
          'title': section.body.childMdx.tableOfContents.items[0].title,
          'url': section.body.childMdx.tableOfContents.items[0].url,
          'depth': section.body.childMdx.headings[0].depth,
        });
      }
    })
  }

  //create child of toc
  sectionData.reduce(function (result, section, index, array) {
    if (section.id === -1) {
      result.push(section);
    } else {
      let prevDepth = array[index - 1].depth;
      let curDepth = section.depth;

      if (curDepth == 2) section.parentId = -1;

      else if (curDepth > prevDepth) {
        array[index].parentId = array[index - 1].id;
      } else if (curDepth < prevDepth) {
        array[index].parentId = array[index - 1].parentId;
      } else {
        array[index].parentId = array[index - 1].parentId;
      }
    }
    return result;
  }, []);

  // create tree of toC
  const idMapping = sectionData.reduce((acc, el, i) => {
    acc[el.id] = i;
    return acc;
  }, {});

  let sectionToCData = [];
  sectionData.forEach(el => {
    // Handle the root element
    if (el.parentId === null) {
      sectionToCData = el;
      return;
    }
    // Use our mapping to locate the parent element in our data array
    const parentEl = sectionData[idMapping[el.parentId]];
    // Add the child to the parent
    parentEl.items = [...(parentEl.items || []), el];
  });

  function renderItems(tocData: any) {
    return (
      <>
        {
          tocData.map(heading => {
            return (
              <li key={heading.url}>
                <p id={heading.url} activeClassName="">{heading.title?.replace(/(<([^>]+)>)/ig, "")}</p>
                {heading.items && renderItems(heading.items)}
              </li>
            );
          })
        }
      </>
    );
  };

  return (
    <ToCContainer ref={toCRef} show={show}>
      <h6 style={{ marginLeft: "10px" }}>Contents</h6>
      <TocList>
        {renderItems(tocData)}
        {sectionToCData?.items && renderItems(sectionToCData.items)}
      </TocList>
    </ToCContainer>
  );
};

const ToCContainer = styled.nav<{
  show: boolean;
}>`
  outline: none;
  font-size:0.85em;
  padding-left:10px;
  padding-right:30px;
  height: calc(88vh - 40px);
  word-break: keep-all;
  max-width: 280px;
  max-height: 425px;
  
  p, a, a:visited{
    color: #ccc;
    pointer-events: none;
  }

  p.active, a.active{
    color: ${p => p.theme.colors.accent};
    border-left: 3px solid ${p => p.theme.colors.accent};
    padding: 5px;
    margin: -5px;
  }

  a:hover{
    color: ${p => p.theme.colors.accent}
  }
  
  opacity: ${p => (p.show ? 1 : 0)};
  visibility: ${p => (p.show ? "visible" : "hidden")};
  transition: ${p =>
    p.show
      ? "opacity 0.1s linear, visibility 0.1s linear"
      : "opacity 0.1s linear, visibility 0.1s linear"};

  ${mediaqueries.desktop_medium`
      display: none;
  `}

`;

const TocList = styled.ol`
  list-style:none;
  li{
    padding-top:5px;
    margin-left:10px;
  }
`;


export default TableOfContents;