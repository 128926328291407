import React from "react";
import styled from "@emotion/styled";

import MDXRenderer from "@narative/gatsby-theme-novela/src/components/MDX";
import Section from "@narative/gatsby-theme-novela/src/components/Section";

import { IArticleSection } from '@narative/gatsby-theme-novela/src/types';

interface ArticleBodyProps {
    section: IArticleSection
}

const ArticleBody = ({ section }: ArticleBodyProps) => {
    return (
        <ArticleBodyContainer>
            <MDXRenderer content={section.body.childMdx.body}>
            </MDXRenderer>
        </ArticleBodyContainer>
    );
};

export default ArticleBody;

const ArticleBodyContainer = styled(Section)`
    margin-top: 35px;
`;