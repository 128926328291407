import React from "react";
import styled from "@emotion/styled";

import MDXRenderer from "@narative/gatsby-theme-novela/src/components/MDX";

import Section from "@narative/gatsby-theme-novela/src/components/Section";
import { IArticleSection } from '@narative/gatsby-theme-novela/src/types';

interface ArticleBodyAlignCenterProps {
    section: IArticleSection
}

const ArticleBodyAlignCenter = ({ section }: ArticleBodyAlignCenterProps) => {
    return (
        <ArticleBodyAlignCenterContainer>
            <MDXRenderer content={section.body.childMdx.body}>
            </MDXRenderer>
        </ArticleBodyAlignCenterContainer>
    );
};

export default ArticleBodyAlignCenter;

const ArticleBodyAlignCenterContainer = styled(Section)`
    margin-top: 35px;
    text-align: center;
`;