import React from "react";
import styled from "@emotion/styled";

import Section from "@narative/gatsby-theme-novela/src/components/Section";
import { IArticleCallToActionButton } from "@narative/gatsby-theme-novela/src/types";

import mediaqueries from '@styles/media';

interface ArticleCallToActionButtonProps {
    section: IArticleCallToActionButton;
}

const ArticleCallToActionButton = ({ section }: ArticleCallToActionButtonProps) => {
    return (
        <Section>
            <CallToActionButtonContainer>
                <CallToActionText>{section.text}</CallToActionText>
                <CallToActionButtonAnchor
                    href={section.url}
                    rel="nofollow"
                    target='_blank'>
                    <CallToActionButton>
                        {section.buttonText}
                    </CallToActionButton>
                </CallToActionButtonAnchor>
            </CallToActionButtonContainer>
        </Section>
    );
};

export default ArticleCallToActionButton;

const CallToActionButtonContainer = styled.div`
    margin: 0 auto;
    margin-top: 35px;
    display: block;
    text-align: center;
    padding: 0 1rem 0 1rem;
    border-radius: 0.5rem;
    border: 1px solid #e0e0e0;
    background-color: ${props => props.theme.colors.background};
    color: ${props => props.theme.colors.primary};

    width: 100%;
    max-width: 680px;
    ${mediaqueries.desktop`
    max-width: 507px;
    `}

    ${mediaqueries.tablet`
    max-width: 486px;
    `};

    ${mediaqueries.phablet`
    padding: 0 20px;
    `};
`;

const CallToActionText = styled.p`
    margin-top: 1rem;
    margin-bottom: 1rem;
    font-weight: 500;
    color: ${props => props.theme.colors.primary};
`;


const CallToActionButtonAnchor = styled.a`
    text-decoration: none;
    color: ${p => p.theme.colors.background};

    &:visited {
        color: ${p => p.theme.colors.background};
    }

    &:hover,
    &:focus {
        text-decoration: underline wavy;
    }
`;

const CallToActionButton = styled.button`
    width: 100%;
    display: block;
    margin: 0 auto;
    margin-bottom: 1rem;
    padding: 0.8rem 1rem;
    font-weight: bold;
    text-decoration: none;
    color: ${props => props.theme.colors.background};
    background-color: ${props => props.theme.colors.accent};
    border-radius: 0.25rem;
    border: 0;
    font-weight: bold;
    text-transform: uppercase;
    cursor: pointer;
    outline: 0;
`;