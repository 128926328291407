import React from "react";
import styled from "@emotion/styled";

import Section from "@narative/gatsby-theme-novela/src/components/Section";
import Paragraph from "@narative/gatsby-theme-novela/src/components/Paragraph";
import { IArticleSection } from '@narative/gatsby-theme-novela/src/types';

interface ArticleAnchorWithColorAlignCenterProps {
    section: IArticleSection;
}

const ArticleAnchorWithColorAlignCenter = ({ section }: ArticleAnchorWithColorAlignCenterProps) => {
    return (
        <StyledSection>
            <StyledAnchor
                href={section.url}
                target='_blank'>
                <Paragraph style={{ color: section.color }}>{section.text}</Paragraph>
            </StyledAnchor>
        </StyledSection>
    );
};

export default ArticleAnchorWithColorAlignCenter;

const StyledSection = styled(Section)`
    text-align: center;
`;

const StyledAnchor = styled.a`
    text-decoration: none;

    &:visited {
        color: ${p => p.theme.colors.secondary};
    }

    &:hover,
    &:focus {
        text-decoration: underline wavy;
    }
`;

